/* src/pages/AboutMe.css */

.about-me {
    display: flex;
    flex-direction: row; /* Side-by-side layout initially */
    justify-content: space-between;
    padding: 20px;
    color: white; /* Black text color */
    background-color: black; /* White background color */
    box-sizing: border-box; /* Include padding and border in width calculations */
    gap: 20px; /* Space between columns */
}

.cv-section {
    flex: 1; /* Allow CV section to take up 70% of the space */
    min-width: 70%; /* Ensures CV section takes up 70% initially */
    padding-right: 20px; /* Add some padding to separate from blog section */
    border-right: 1px solid black; /* Subtle border to separate sections */
    box-sizing: border-box; /* Include padding and border in width calculations */
}

.blog-section {
    flex: 1; /* Allow Blog section to take up 30% of the space */
    min-width: 30%; /* Ensures Blog section takes up 30% initially */
    padding-left: 20px; /* Add some padding to separate from CV section */
    box-sizing: border-box; /* Include padding and border in width calculations */
}

/* Media query for smaller screens */
@media (max-width: 800px) {
    .about-me {
        flex-direction: column; /* Stack the sections vertically */
        align-items: center; /* Center align when stacked */
        padding: 10px; /* Reduce padding on smaller screens */
    }

    .cv-section,
    .blog-section {
        width: 100%; /* Each section takes full width */
        padding: 0; /* Remove side padding */
        border-right: none; /* Remove the border on the smaller screen */
        margin-bottom: 20px; /* Space between sections when stacked */
    }
}
