.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover; /* Ensures the image covers the entire screen */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    z-index: -1; /* Send the background image behind all other content */
    object-fit: cover; /* Maintain aspect ratio while covering the screen */
}
