/* src/components/SpotifyBox.css */

.spotify-box {
    position: relative;
    width: calc(100% - 20px); /* Full width minus 20px padding on each side */
    max-width: 450px; /* Maximum width of 450px */
    height: auto;
    margin: 20px auto; /* Center the box horizontally with auto margin */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Include padding and border in width */
}
