/* src/components/Home.css */

.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 2rem;
  position: relative; /* Ensure content is positioned above the background */
  z-index: 1; /* Ensure content appears above the background */
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  color: white;
  padding: 0 2rem;
  box-sizing: border-box;
}

.column-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0 10px; /* Add padding for smaller screens */
  gap: 0; /* Remove gap between columns */
}

.column {
  display: flex;
  flex-direction: column;
  width: 490px; /* Set fixed width for each column */
  gap: 0; /* Remove gap between components to use custom spacing */
  padding: 0; /* Remove padding */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.column .component {
  order: 0;
}

.spacer {
  width: 100%; /* Ensure the spacer takes up the full width of the column */
}

/* Media query for small screens */
@media (max-width: 768px) {
  .column {
    width: 100%; /* Full width for smaller screens */
    max-width: calc(100% - 20px); /* Ensure columns fit with padding */
    padding: 0 10px; /* 10px padding on both sides */
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is above all other elements */
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border-radius: 10px;
}
