.navbar {
    //background-color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    padding: 1rem;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center; /* Centers the ul horizontally */
    z-index: 100;
}

.navbar-list {
    list-style: none;
    display: flex;
    gap: 2rem;
    padding: 0;
    margin: 0;
}

.navbar ul {
    list-style: none;
    display: flex;
    gap: 2rem;
    padding: 0;
    margin: 0;
}

.navbar a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    display: inline-block;
    text-align: center;
}
