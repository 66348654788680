/* src/components/TextBox.css */

.text-box {
    position: relative;
    width: calc(100% - 20px); /* Full width minus 20px padding on each side */
    max-width: 450px; /* Maximum width of 450px */
    margin: 20px auto; /* Center the box horizontally with auto margin */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px; /* Adjust padding to match ImageBox */
    border: 5px solid white; /* 5-pixel wide white frame */
    background-color: transparent; /* Make the background transparent */
    box-sizing: border-box; /* Ensures padding and border are included in width */
}

.text-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: white; /* White text color for the title */
}

.text-body {
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    color: white; /* White text color for the body */
}

.text-link {
    color: blue; /* URL text remains blue */
    text-decoration: underline;
    margin-bottom: 8px;
}

.italic-line {
    font-style: italic;
    font-size: 12px;
    color: white; /* White text color for the italic line */
    text-align: center;
    margin-top: 8px;
}
