/* src/components/Boxes/SocialBox.css */

.social-box {
    position: relative;
    width: calc(100% - 20px); /* Full width minus 20px padding on each side */
    max-width: 450px; /* Maximum width of 450px */
    margin: 20px auto; /* Center the box horizontally with auto margin */
    padding: 16px;
    border-radius: 8px;
    border: 5px solid; /* Border color set dynamically in JS */
    background-color: transparent; /* Make the background transparent */
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; /* Include padding and border in width */
}

.social-content {
    display: grid; /* Change to grid display */
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)); /* Responsive grid with minimum column width */
    gap: 10px; /* Space between icons */
    justify-items: center; /* Center icons horizontally within their grid cells */
    align-items: center; /* Center icons vertically within their grid cells */
    width: 100%;
}

.social-icon {
    text-decoration: none;
    display: flex;
    align-items: center;
}
