/* src/components/ImageBox.css */

.image-box {
    position: relative;
    width: calc(100% - 20px); /* Full width minus 20px padding on each side */
    max-width: 450px; /* Maximum width of 450px */
    margin: 20px auto; /* Center the box horizontally with auto margin */
    border-radius: 8px; /* Add rounded corners */
    overflow: hidden; /* Ensure the image doesn't overflow outside the rounded corners */
    box-shadow: none; /* Remove any shadow */
    background-color: transparent; /* Make the background invisible */
    display: flex;
    flex-direction: column; /* Arrange items vertically */
    align-items: center; /* Center align the content horizontally */
    justify-content: flex-start; /* Align items to the top */
    padding: 0; /* Remove any padding */
    box-sizing: border-box; /* Include padding and border in width */
}

.image-box img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: contain;
    display: block;
    border-radius: 8px; /* Ensure the image has rounded corners */
}

.caption-top, .caption-bottom {
    color: white;
    font-size: 14px;
    text-align: center;
    margin: 0; /* Remove margin to eliminate extra space */
    padding: 0; /* Remove padding to eliminate extra space */
}

.caption-top {
    margin-bottom: 4px; /* Small space between top caption and image */
}

.caption-bottom {
    margin-top: 4px; /* Small space between image and bottom caption */
}
