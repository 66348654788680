/* src/components/BlogComponent.css */

.blog-component {
    font-family: 'Arial', sans-serif;
    color: white; /* Black text color */
    background-color: black; /* White background color */
    padding-left: 20px;
    padding-right: 20px;
    width: 100%; /* Full width for responsive design */
    box-sizing: border-box; /* Include padding in width */
}

.blog-component h2 {
    margin-bottom: 20px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}

.post {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.post h3 {
    margin: 0;
}

.post p {
    margin: 10px 0;
}

.post a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.post a:hover {
    text-decoration: underline;
}

.italic {
    font-style: italic;
    color: #919191; /* A slightly lighter color for the italic line */
    margin-top: 10px;
}
